<template>
  <ion-page>

    <!-- Page Content -->
    <ion-content>
      <!-- Header -->
      <ModalHeader page="genres" />
      <!-- Home Feed -->
      <div class="app-max-width">
        <ion-item v-for="genre in genres" :key="genre" @click.prevent="updateItems(genre)" button>
          <div tabindex="0"></div><!-- bug fix for safari 14 -->
          <ion-icon v-if="genre.isChecked === false" :icon="squareOutline" color="dark"></ion-icon>
          <ion-icon v-if="genre.isChecked === true" :icon="checkbox" color="success"></ion-icon> 
          <ion-text class="margin-left-md">{{genre.name}}</ion-text>
        </ion-item>

      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonItem, IonIcon, IonText } from '@ionic/vue';
import { defineComponent, ref, computed } from 'vue';
import doAuth from '../../services/auth';
import ModalHeader from '../../components/ModalHeader.vue';
import { useStore } from "vuex";
import apiClient from '../../services/api';
import { squareOutline, checkbox } from 'ionicons/icons';

export default defineComponent({
  name: 'AddGenres',
  components: {
    IonContent, IonPage, IonItem, ModalHeader, IonIcon, IonText
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
    this.getGenres()
  },
  setup() {
    const store = useStore();
    const genres = ref([]);
    const storedGenres = computed(() => store.state.storedGenres);

    // Get genres from DB
    function getGenres() {
      apiClient.get('/api/genres', {
        headers: {
          'Authorization':  `Bearer ${store.state.authToken}`
        }
      }).then(response => {
        console.log('genres')
        genres.value = response.data
        doCheckedGenres()
      }).catch(error => {
        console.log(error)  
      });
    }

    function doCheckedGenres() {
      if(genres.value.length > 0) {
        for(var i=0;i<genres.value.length;i++) {
          genres.value[i].isChecked = isStored(genres.value[i].id)
        }
      }
    }

    function checkedGenre(id) {
      if(genres.value.length > 0) {
        for(var i=0;i<genres.value.length;i++) {
          if(genres.value[i].id == id) {
            if(genres.value[i].isChecked === true) {
              genres.value[i].isChecked = false
            } else {
              genres.value[i].isChecked = true
            }
          }
        }
      }
    }

    function isStored(id) {
      var inGenres = false
      if(storedGenres.value.length > 0) {
        for(var i=0;i<storedGenres.value.length;i++) {
          if(storedGenres.value[i].id == id) {
            inGenres = true
          }
        }        
      }
      return inGenres
    }

    function updateItems(item) {
      var stored = false
      // add or remove from store
      if(storedGenres.value.length > 0) {
        stored = isStored(item.id)
      }
      // Remove from store
      if(stored === true) {
        if(storedGenres.value.length > 0) {
          for(var i=0;i<storedGenres.value.length;i++) {
            if(storedGenres.value[i].id == item.id) {
              removeFromStore(i)
            }
          }        
        }        
      } 
      // Add to store
      else {
        addToStore(item)
      }
      // update isChecked
      checkedGenre(item.id)
    }

    function addToStore(item) {
      store.commit('setStoredGenre', {
        id: item.id,
        name: item.name
      })
    }

    function removeFromStore(i) {
      store.commit('removeStoredGenre', i)
    }

    return {
      store, genres, getGenres, storedGenres, updateItems, squareOutline, checkbox
    }
  }
});
</script>

<style scoped>

</style>
